import {useMemo} from "react";
import createI18nFactory from "@gotombola/react-i18n/lib/utils/createI18nFactory";
import configs from '../apps';
import HttpApi from 'i18next-http-backend';

export function useMode(mode: string|undefined) {
    return useMemo(() => ({
        pixel: configs.pixels[mode || ''] || configs.pixels['default'] || {},
        push: configs.pushs[mode || ''] || configs.pushs['default'] || {},
        modules: configs.modules[mode || ''] || configs.modules['default'] || {},
        publicPages: configs.publicPages[mode || ''] || configs.publicPages['default'] || {},
        templates: configs.templates[mode || ''] || configs.templates['default'] || {},
        workflows: configs.workflows[mode || ''] || configs.workflows['default'] || {},
        actions: configs.actions[mode || ''] || configs.actions['default'] || {},
        sitemaps: configs.sitemaps[mode || ''] || configs.sitemaps['default'] || {},
    }), [mode]);
}

const fns: Record<string, Function> = {};

export function createI18n(locale?: string, mode?: string) {
    const key = `${locale || ''}-${mode || ''}`;
    if (!fns[key]) fns[key] = createI18nFactory(
        configs.configs[mode || ''] || configs.configs['default'] || {},
        (l: string) => [
            require(`@gotombola/react-ds/translations/${l}.json`),
            require(`../../translations/${l}.json`),
        ],
        () => (process && !process.release) ? [HttpApi] : [],
        () => ((process && !process.release) ? {
            backend: {
                loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}',
                addPath: '/locales/add/{{lng}}/{{ns}}',
                allowMultiLoading: true,
                crossDomain: false,
                withCredentials: false,
                overrideMimetype: false,
            },
        } : {})
    )(locale, mode);
    return fns[key];
}

import React, {useMemo} from 'react';
import createRoutesBuilder from "@gotombola/react-ssr/lib/common/utils/createRoutesBuilder";
import hydrateClient from "@gotombola/react-ssr/lib/client/utils/hydrateClient";
import {createI18n} from "./utils/common";
import App from "./App";
import configs from './apps';
// noinspection ES6UnusedImports @ts-ignore
import {register} from 'razzle-plugin-workbox/service-worker';

function ModeApp(props: any) {
    const routes = useMemo(() => props.routes || createRoutesBuilder(configs.mounts[props.mode || 'default']), [props.routes, props.mode])
    return <App {...props} routes={routes} templateDefinitions={configs.templateDefinitions[props.mode || 'default']} />;
}

// noinspection JSUnusedLocalSymbols
function enrichedRegister(context: any) {
    // warning: do not uncomment this line without knowing what you are doing.
    // service worker completely change the behaviour of browser cache.
    /*
    register({
        swDest: 'service-worker.js',
    });
     */
}

// noinspection JSIgnoredPromiseFromCall
hydrateClient({App: ModeApp, createI18n, register: enrichedRegister});
module.hot?.accept();
import buildAppsCommonConfigs from '@gotombola/react-ssr/lib/common/utils/buildAppsCommonConfig';
import * as game from './game';
import * as pages from './pages';
import * as organization from './organization';
import * as campaign from './campaign';
import * as partner from './partner';
import * as tenant from './tenant';
import * as sales from './sales';
import * as redirect from './redirect';
import * as errors from './errors';

export const defaultApp = 'pages';

export default buildAppsCommonConfigs(defaultApp, {
    game,
    pages,
    organization,
    campaign,
    partner,
    tenant,
    sales,
    redirect,
    errors,
}, {
    defaultLocale: 'fr-FR',
    fallbackLocale: 'fr-FR',
    defaultWidget: undefined,
    service: 'pages',
});